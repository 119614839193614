<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: battery.vue
Description:This file is the child component of the DeviceHealth.This file gives the information about the battery details and functionality like search and buttons like  active inactive  and All ponds
-->
<template>
  <el-row
    class="battery-component"
    v-loading="loading"
    element-loading-background="white"
  >
    <Loader v-if="loading"></Loader>
    <el-row class="battery" v-if="!loading">
      <er-data-tables
        :table-data="tableDataArr"
        :columns="tableColumns"
        :el-table-props="tableProps"
        :pagination-props="$constants.table['pagination-props']"
        uniqueId="battery-details"
        :merge="{ startColumnNumber: 1, endColumnNumber: 2 }"
        :actionColumn="false"
        :sortMethodForColumns="sortMethodForColumns"
        type="white-header-table"
        class="battery-table"
        :searchOnTableData="getTableFilters"
        :key="$i18n.locale"
      >
        <!-- keyValueBasedOnPrefferedUnits="true" -->
        <template v-slot:pond_title="{ row }">
          <er-highlight-search-component
            :highlightStrings="row.data.highlightStrings"
            :individualRowData="row.data"
            keyName="pond_title"
            :withoutHighlightStrings="row.data.pond_title"
          />
        </template>

        <template v-slot:title="{ row }">
          <template
            v-if="row.data.device_type === $constants.deviceTypes.SHRIMP_TALK.key"
          >
            <er-highlight-search-component
              :highlightStrings="row.data.highlightStrings"
              :individualRowData="row.data"
              :keyName="getDeviceNameUnits"
              :withoutHighlightStrings="upm__getValue(row.data)"
            />
          </template>
          <template v-else>
            <er-highlight-search-component
              :highlightStrings="row.data.highlightStrings"
              :individualRowData="row.data"
              :keyName="getDeviceNameUnits"
              :keyValueBasedOnPrefferedUnits="true"
              :withoutHighlightStrings="upm__getValue(row.data)"
            />
          </template>
        </template>

        <template v-slot:device_type="{ row }">
          <er-highlight-search-component
            :highlightStrings="row.data.highlightStrings"
            :individualRowData="row.data"
            keyName="device_type_lang"
            :withoutHighlightStrings="row.data.device_type_lang"
          />
        </template>
        <template v-slot:battery_voltage="{ row }">
          <span :style="generateColorForBatterVoltage(row.data)">
            {{ ftm__formatEmpty(batteryVoltage(row.data)) }}
          </span>
        </template>
        <template v-slot:time="{ row }">
          {{ lastCommunicationDate(row.data) }}
        </template>
        <template v-slot:comm_status="{ row }">
          <span :style="generateColorForStatus(row.data)">
            {{ row.data.comm_status }}
          </span>
        </template>
      </er-data-tables>
    </el-row>
  </el-row>
</template>

<script>
import { mapGetters } from "vuex";
import featureToggleMixin from "@/mixins/featureToggleMixin";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import erHighlightSearchComponent from "../base/erHighlightSearchComponent.vue";
import Loader from "@/components/base/Loader";

export default {
  components: { erHighlightSearchComponent, Loader },
  name: "BatteryPage",
  inject: ["parentConfig"],
  mixins: [
    errorHandlerMixin,
    filtersMixin,
    featureToggleMixin,
    userPreferenceMixin,
    datesHandlerMixin
  ],

  data: function () {
    return {
      loading: false,
      tableProps: {
        size: "small",
        defaultSort: {
          prop: "device_sort_key",
          order: "ascending"
        },
        height: "calc(100vh - var(--height-gutter))"
      }
    };
  },
  computed: {
    ...mapGetters("user", {
      getPreferredUnits: "getPreferredUnits"
    }),
    ...mapGetters("pond", {
      getShrimpTalkDeviceStatus: "getShrimpTalkDeviceStatus",
      getPondMotherDeviceStatus: "getPondMotherDeviceStatus",
      getPondGuardDeviceStatus: "getPondGuardDeviceStatus",
      getFeedBlowerDeviceStatus: "getFeedBlowerDeviceStatus"
    }),
    getDeviceNameUnits() {
      return this.getPreferredUnits.device_name === "alias_name"
        ? "title"
        : "code";
    },
    getTableFilters() {
      return this.parentConfig.searchOnData;
    },
    feedBlowerFeatureReq: function () {
      return {
        featureAllowedUsers: [
          "5e1897b5188b23258b34654d",
          "5e565a0cbc2c4dd8b5f6f041",
          "5e5cfb322c599d13d25b607e",
          "5f815e7987023253993597e5"
        ]
      };
    },
    getPonds() {
      return this.$store.getters["pond/getPondsObj"];
    },
    sortMethodForColumns: function () {
      return {
        pond_title: this.$commonUtils.alphaNumericComparator,
        device_sort_key: this.$commonUtils.alphaNumericComparator
      };
    },
    getSelectedStatus() {
      return this.parentConfig.selectedStatus;
    },
    tableColumns() {
      return {
        pond_title: {
          prop: "pond_title",
          label: this.$t("Comn_pond_name"),
          enableSearch: true,
          searchProperty: "pond_title",
          minWidth: "150",
          sortable: true,
          sortMethod: (a, b) =>
            this.$commonUtils.alphaNumericComparator(
              a.pond_title,
              b.pond_title
            ),
          align: "left"
        },
        device_type: {
          prop: "device_type",
          enableSearch: true,
          searchProperty: "device_type_lang",
          label: this.$t("Comn_device_type"),
          minWidth: "100",
          sortable: true
        },
        title: {
          prop: "device_sort_key",
          enableSearch: true,
          searchProperty:
            this.getPreferredUnits.device_name === "code" ? "code" : "title",
          label: this.$t("Comn_device_id"),
          minWidth: "100",
          sortable: "custom"
        },
        battery_voltage: {
          prop: "battery_voltage",
          label: this.$t("Comn_voltage"),
          minWidth: "100",
          sortable: true
        },
        comm_status: {
          prop: "comm_status",
          label: this.$t("Status"),
          minWidth: "100",
          sortable: true,
          enableSearch: true,
          searchProperty: "comm_status"
        },
        time: {
          prop: "date",
          label: this.$t("Comn_time"),
          minWidth: "160",
          sortable: true,
          sortMethod: (a, b) =>
            this.dhm__dateUtilsLib.dateComparator(
              a.last_communicated_at,
              b.last_communicated_at,
              true
            )
        }
      };
    },
    lastCommunicationDate() {
      return (data) => {
        if (
          data.last_communicated_at &&
          this.dhm__dateUtilsLib.isValid(new Date(data.last_communicated_at))
        ) {
          return this.dhm__dateUtilsLib.getFormatByTimeZoneOnUTC(
            data.utc_last_communicated_at,
            this.upm__getFormatDateAndTimeString,
            this.getUserTimeZoneString,
            this.dhm__getLocaleObj
          );
        } else {
          return "--";
        }
      };
    },
    batteryVoltage() {
      return (data) => {
        return data.battery_voltage;
      };
    },

    generateColorForBatterVoltage() {
      return (data) => {
        const bv = this.batteryVoltage(data);
        if (!bv) return "#00000";
        if (data.device_type === this.$constants.deviceTypes.POND_GUARD.key) {
          if (bv <= 3.3) {
            return { color: "Red" };
          } else if (bv > 3.3 && bv <= 3.7) {
            return { color: "Orange" };
          } else if (isNaN(bv)) {
            return { color: "Black" };
          } else {
            return { color: "Green" };
          }
        } else if (
          [
            this.$constants.deviceTypes.POND_MOTHER.key,
            this.$constants.deviceTypes.SHRIMP_TALK.key,
            this.$constants.deviceTypes.FEED_BLOWER.key
          ].includes(data.device_type)
        ) {
          if (bv <= 11) {
            return { color: "Red" };
          } else if (bv > 11 && bv <= 12) {
            return { color: "Orange" };
          } else if (isNaN(bv)) {
            return { color: "Black" };
          } else {
            return { color: "Green" };
          }
        }
      };
    },
    generateColorForStatus() {
      return (data) => {
        const deviceStatus = data.comm_status;
        const obj = {
          "En línea": { color: "Green", "font-weight": 550 },
          Online: { color: "Green", "font-weight": 550 },
          Offline: { color: "#bdbdbd", "font-weight": 550 },
          Desconectada: { color: "#bdbdbd", "font-weight": 550 }
        };
        return obj[deviceStatus];
      };
    },
    deviceStatus() {
      return (data) => {
        return data.comm_status ? "ONLINE" : "OFFLINE";
      };
    },
    tableDataArr: function () {
      const arrValues = [
        this.getShrimpTalkDeviceStatus(this.getSelectedStatus),
        this.getPondMotherDeviceStatus(this.getSelectedStatus),
        this.getPondGuardDeviceStatus(this.getSelectedStatus),
        this.ftgm__isFeatureAvailable(this.feedBlowerFeatureReq)
          ? this.getFeedBlowerDeviceStatus(this.getSelectedStatus)
          : []
      ];
      return arrValues.flat(1);
    }
  },
  mounted() {
    this.loading = true;
    this.$nextTick(() => {
      if (this.$refs.table) {
        console.log(this.$refs.table.$refs.dataTables.$refs.elTable);
        this.$refs.table.$refs.dataTables.$refs.elTable.doLayout();
      }
    });
    this.upm__setDataKeys("code", "title");
    this.loading = false;
  },
  methods: {
    isKeyExists(item, value) {
      return this.$commonUtils.isKeyExists(item, value);
    },

    isNullOrUndefined(variable) {
      if (variable === undefined || variable === null) {
        return true;
      }
      return false;
    },
    toFixedAndParseFloat(val) {
      return parseFloat(Number(val).toFixed(3));
    },
    batteryStatusBasedOnDate(deviceType, diff) {
      const inActiveTime = this.getTimeForInactive(deviceType);
      return diff < 0 || diff <= inActiveTime ? "ONLINE" : "OFFLINE";
    },
    getTimeForInactive(deviceType) {
      let time = 1800;
      switch (deviceType) {
        case this.$constants.deviceTypes.POND_MOTHER.key:
          time = 1800;
          break;
        case this.$constants.deviceTypes.POND_GUARD.key:
          time = 1800;
          break;
        case this.$constants.deviceTypes.SHRIMP_TALK.key:
          time = 900;
          break;
        default:
          time = 1800;
          break;
      }
      return time;
    }
  }
};
</script>

<style lang="scss">
.battery {
  .header {
    // margin-bottom: 10px;
  }
  .pond-device-status-table {
    $default-font-size: 13px;
    $font-color: #404243;
    h3 {
      font-weight: 600;
    }

    .battery-table {
      .pagination-bar {
        display: flex;
        justify-content: center;
      }
      .el-pagination__total {
        margin-right: 0px;
        // font-weight: 400;
        // color: #606266;
        margin-left: 1rem;
      }
    }
    .refresh-icon {
      display: flex;
      justify-content: inherit;
      img {
        margin-left: 16px;
        width: 16px;
      }
    }
    .el-card__body {
      padding-top: 8px;
    }
    .row-1 {
      margin-top: 8px;
      margin-bottom: 16px;
      .el-card__header {
        border-bottom: 0px solid #ebeef5;
      }
      .search-bar-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 0.2rem;

        .search-bar {
          position: relative;
          padding-right: 4px;
          margin-left: 12px;
          width: 180px;
          input {
            background: #f2f3f4;
            border: 0px solid #dcdfe6;
            border-radius: 8px;
            color: black !important;
            width: 180px;
          }
          img {
            width: 16px;
            position: absolute;
            bottom: 0;
            right: 0;
            margin: 6px;
          }
          .el-input--small .el-input__inner {
            height: 28px;
            line-height: 28px;
          }
        }
      }
    }
    .el-table__header th {
      .cell {
        font-weight: bold;
      }
    }
    .el-table {
      border-radius: 10px;
      box-shadow: 0px 1px 6px #00000029;

      .el-table__body-wrapper {
        min-height: 60vh;
      }
      .cell {
        text-align: center;
        font-size: $default-font-size;
        .el-tag {
          font-size: $default-font-size;
        }
      }
      .el-table__header {
        tr {
          background-color: #44a1ff;
          color: white;
        }
        th {
          background-color: transparent;
          &:nth-last-child(2) {
            .cell {
              border-right: none;
            }
          }
          & > .cell {
            display: inline-block;
            -webkit-box-sizing: border-box;
            border-right: 1px solid white;
            box-sizing: border-box;
            position: relative;
            vertical-align: middle;
            padding-left: 10px;
            padding-right: 10px;
            width: 100%;
            height: 32px;
            line-height: 32px;
          }
          // .sort-caret {
          //   &.ascending {
          //     border-bottom-color: transparent;
          //   }
          //   &.descending {
          //     border-top-color: transparent;
          //   }
          // }
        }
      }
    }

    .is-sortable {
      &.ascending .sort-caret.ascending {
        border-bottom-color: #ffffff;
      }
      &.descending .sort-caret.descending {
        border-top-color: #ffffff;
      }
    }
    .el-pagination {
      margin-top: 20px;
      vertical-align: middle;
      .el-pagination__sizes {
        .el-input--mini {
          .el-input__icon {
            line-height: 22px;
          }
          .el-input__inner {
            height: 22px;
            line-height: 22px;
          }
        }
      }
    }
  }
  @include responsiveProperty(--height-gutter, 197px, 205px, 215px);
}
</style>
