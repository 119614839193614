<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: deviceHealth.vue
Description:This file contains combination of two files (feedLevelTab,batteryTab)
-->
<template>
  <layout-pondlogs headerType="none" class="device-health">
    <template slot="layout-pondlogs-scrollable-main">
      <er-card>
        <er-top-route-nav
          type="tabs"
          :items="computedMenuItems"
        ></er-top-route-nav>
        <layout-toolbar :gap="5">
          <el-radio-group
            :value="selectedStatus"
            size="small"
            @input="handleStatusChange"
          >
            <el-radio-button label="ACTIVE" class="is-plain">
              {{ $t("Comn_active") }}
            </el-radio-button>
            <el-radio-button label="INACTIVE" class="is-plain">
              {{ $t("Comn_inactive") }}
            </el-radio-button>
            <el-radio-button label="ALL" class="is-plain">
              {{ $t("Comn_all") }}
            </el-radio-button>
          </el-radio-group>
          <el-col :span="4.5">
            <er-input
              inputType="search"
              v-model="searchOnData"
              size="mini"
              suffix-icon="el-icon-search"
              :placeholder="$t('Pond_search_pond')"
              @change="handelSearch"
            ></er-input>
          </el-col>
          <span class="el-col el-col-1">
            <er-button
              btnType="refresh"
              :showIcon="true"
              type="text"
              size="mini"
              @click="initComponent('refresh')"
            />
          </span>
        </layout-toolbar>
        <!-- &nbsp; -->
        <component
          :is="computedComponentName"
          :tabData="components[computedComponentName]"
          v-loading="loading"
          @refetch="initComponent"
        ></component>
      </er-card>
    </template>
  </layout-pondlogs>
</template>

<script>
import batteryTab from "@/components/deviceHealth/battery";
import feedLevelTab from "@/components/deviceHealth/feedLevel";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import featureToggleMixin from "@/mixins/featureToggleMixin";
import redirectsMixin from "@/mixins/redirectsMixin";
import { deviceTypes } from "@/constants/deviceTypes";
import {
  permissionsToStringMap as permissions,
  isValidTabName,
  getFirstTabBasedOnPermissions
} from "@/middleware/pageAccessManager";
import { ALL_USERS as FEATURE_ALL_USERS } from "@/middleware/featureToggleManager";
import { mapActions } from "vuex";
const tabToPermission = {
  batteryTab: {
    name: "batteryTab",
    featureAllowedUsers: FEATURE_ALL_USERS,
    keyToValidateAccess: "PERMISSIONS",
    PERMISSIONS: [permissions.MANAGE_CULTURE, permissions.MANAGE_DEVICES]
  },
  feedLevelTab: {
    name: "feedLevelTab",
    featureAllowedUsers: [
      "5e1897b5188b23258b34654d",
      "5e565a0cbc2c4dd8b5f6efd0",
      "5e1897b5188b23258b34645d",
      "5e565a0cbc2c4dd8b5f6f041",
      "5f1926e917a2492077983958",
      "5e5cfb322c599d13d25b607e",
      "5e565a0cbc2c4dd8b5f6f02a",
      "5f815e7987023253993597e5",
      "5e72fdf83408bd911233ea3a",
      "6143570a44af69f49f8b1142",
      "5e1897b5188b23258b3464ae",
      "5e72f9493408bd9112319685",
      "5f6a182c0fa44b545871ecb7",
      "612f8bdadf03da181fb555b2",
      "5e1897b5188b23258b34636b",
      "62419c7a3578cfecd17e3ad8",
      "61ae25b3ef470d2fd2353712"
    ],
    keyToValidateAccess: "PERMISSIONS",
    PERMISSIONS: [permissions.MANAGE_CULTURE, permissions.MANAGE_DEVICES]
  }
};

export default {
  name: "DeviceHealth",
  mixins: [errorHandlerMixin, featureToggleMixin, redirectsMixin],
  provide() {
    return {
      parentConfig: this.$data
    };
  },
  components: {
    batteryTab,
    feedLevelTab
  },
  data: function () {
    return {
      loading: false,
      searchOnData: "",
      selectedStatus: "ACTIVE",
      filters: [
        {
          value: "",
          filterFn: (row, filter) => {
            return Object.keys(row).some((prop) => {
              if (
                ["device_type", "pond_title", "code", "title"].indexOf(prop) >
                -1
              ) {
                if (prop === "device_type") {
                  return deviceTypes[row[prop]].name
                    .toLowerCase()
                    .includes(filter.value.toLowerCase());
                } else {
                  return row[prop]
                    .toLowerCase()
                    .includes(filter.value.toLowerCase());
                }
              }
            });
          }
        }
      ]
    };
  },
  created() {
    const viewPermissions = this.$store.getters["user/getViewPermissions"];
    const userType = this.$store.getters["user/getCurrUserType"];
    const tabName = this.$route.query.tab;
    if (!isValidTabName(tabName, tabToPermission, viewPermissions, userType)) {
      this.rdm__navigateToPathWithLocationId({
        path: "/user/device-health",
        query: {
          tab: getFirstTabBasedOnPermissions(
            tabToPermission,
            viewPermissions,
            userType
          )
        }
      });
    }
  },
  computed: {
    computedComponentName: function () {
      return this.$route.query.tab;
    },
    getCurrLocation() {
      return this.$store.getters["user/getCurrUserLocation"];
    },
    components() {
      return {
        batteryTab: {
          label: this.$t("Comn_battery"),
          ...tabToPermission.batteryTab
        },
        feedLevelTab: {
          label: this.$t("Comn_feed_level"),
          ...tabToPermission.feedLevelTab
        }
      };
    },
    computedMenuItems: function () {
      const filters = [
        this.$gblUAMFilterItemsUserCanView,
        this.ftgm__filterObjFeatures
      ];
      return filters.reduce((accComponents, filter) => {
        accComponents = filter(accComponents);
        return accComponents;
      }, this.components);
    }
  },
  async mounted() {
    await this.initComponent();
  },
  methods: {
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    async initComponent( data = '') {
      try {
        if (data === 'refresh') {
          this.mixPanelEventGenerator({ eventName: "Device Health - " + this.$route.query.tab + " - Refresh" });
        }
        this.loading = true;
        await this.$store.dispatch("pond/fetchPondWiseDeviceStatus", {
          location_id: this.getCurrLocation._id,
          get_all: true
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    handleStatusChange(status) {
      this.mixPanelEventGenerator({ eventName: "Device Health - " + this.$route.query.tab + " - " + status });
      this.selectedStatus = status;
    },
    handelSearch() {
      if (this.searchOnData !== '') {
        this.mixPanelEventGenerator({ eventName: "Device Health - " + this.$route.query.tab + " - Search" });
      }
    }
  }
};
</script>

<style lang="scss">
.device-health {
  .el-menu--horizontal.type-tab {
    width: 100%;
    margin-bottom: 5px;
    > .el-menu-item {
      line-height: 35px;
      height: 35px;
    }
  }
  .toolbar-layout {
    margin-bottom: 5px;
  }
  .el-radio-group {
    .el-radio-button__inner {
      padding: 7px 15px;
    }
  }
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #ffffff;
    background: $button-color;
    border-color: $button-color;
    box-shadow: -1px 0 0 0 $button-color;
  }
  .el-radio-button__orig-radio + .el-radio-button__inner {
    color: #0a2463fe;
  }
  .el-radio-button__orig-radio:disabled + .el-radio-button__inner {
    opacity: 0.6;
  }
}
</style>
